<template>
  <div class="manage-banker-add">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm">
        <div class="card p-3">
          <h2 class="mb-5">Add Banker</h2>

          <fd-form-section title="Account Credential">
            <fd-input
              v-model="banker.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Official Banker Email"
              name="email"
              type="email"
              :validators="[validator.required, validator.email]"
            >
            </fd-input>
            <fd-input
              v-model="banker.password"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Password"
              name="password"
              :type="showPassword ? 'text' : 'password'"
              :validators="[validator.required, validator.password]"
            >
              <template #append-icon>
                <span
                  class="cursor-pointer"
                  @click="showPassword = !showPassword"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-eye': !showPassword,
                      'fa-eye-slash': showPassword
                    }"
                  ></i>
                </span>
              </template>
            </fd-input>
          </fd-form-section>
          <fd-form-section title="Personal Information">
            <fd-input
              v-model="banker.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="name"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-select
              v-model="banker.bank"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Bank"
              :options="bankOptions"
              :validators="[validator.required]"
            >
            </fd-select>
            <tel-input
              ref="tel"
              v-model="phoneInput"
              :tel.sync="banker.phoneNo"
              class="col-12 px-1 mb-2 d-block"
              label="Contact No."
              required
            >
            </tel-input>
          </fd-form-section>

          <div class="text-right p-2">
            <button class="btn main">Create</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import TelInput from "@/modules/User/components/TelInput";

// Validator
import {
  required,
  password,
  email,
  phone
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
// API
import { banker as bankerAPI } from "@/api";
// Model
import { BankerModel } from "@/models";
// Enums
import { bank } from "@/enums";
// Composable
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

export default {
  setup(props, context) {
    let toBankerList = () => {
      context.root.$router.push({
        name: "ManageBankers"
      });
    };
    const submitHandlerOptions = {
      role: "banker",
      successCallback: toBankerList
    };
    const { handleCreateSuccess, handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleCreateSuccess,
      handleSubmitError
    };
  },
  name: "AddBanker",
  components: { TelInput },
  mixins: [],
  props: {},
  data: function () {
    return {
      bankOptions: this.$mapJsonToArray(bank, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      phoneInput: "",

      banker: {
        email: "",
        passsword: "",

        name: "",
        bank: "",
        phoneNo: {}
      },

      validator: {
        required: required,
        password: password,
        email: email,
        phone: phone
      },

      showPassword: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {},
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        const res = await bankerAPI.createBanker(
          BankerModel.postBankerPayload(this.banker)
        );
        this.$store.commit("setIsLoading", false);

        this.handleCreateSuccess(res);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.handleSubmitError(error);
      }
    }
  }
};
</script>

<style lang="scss">
.manage-banker-add {
  form {
    @extend %formDesign;
  }
}
</style>
